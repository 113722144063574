import { Field } from "formik";
import React from "react";

const Checkbox = ({ isError, name, isChecked, onchange }) => {
  return (
    <>
      <Field
        type="checkbox"
        className={`${
          isError ? "!border-red-500" : null
        } bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded`}
        name={name}
        autoComplete="off"
      />
    </>
  );
};

export default Checkbox;
