import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import cartReducer from "./slices/cart";
import productReducer from "./slices/product";
import departmentReducer from "./slices/department";

const reducer = {
  auth: authReducer,
  cart: cartReducer,
  product: productReducer,
  department: departmentReducer,
};
export const store = configureStore({
  reducer,
  devTools: true,
});
