import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../http";

export const retrieveProducts = createAsyncThunk(
  "products/retrieve",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.getAllProducts();
      if (
        (response.status === 200 || response.status === 201) &&
        response.data
      ) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const productSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    product_image_url: null,
    loading: false,
  },
  extraReducers: {
    [retrieveProducts.pending]: (state) => {
      state.loading = true;
    },
    [retrieveProducts.fulfilled]: (state, action) => {
      state.loading = false;
      state.products = action.payload.data;
      state.product_image_url = action.payload.url;
    },
    [retrieveProducts.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default productSlice.reducer;
