import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Label, SelectInput, TextInput } from "../../../../components";
import PaymentNav from "../PaymentNav/PaymentNav";
import * as Yup from "yup";

const Sales = () => {
  const [salesInitialValues, setSalesInitialValues] = useState({
    payment_type: "",
    customer: "",
    sales: "",
    total_payment: "",
    paid_amount: "",
    pending_amount: "",
    payment_mode: "",
    amount: "",
    remarks: "",
  });

  const [customers, setCustomers] = useState([]);
  const [sales, setSales] = useState([]);

  const SalesSchema = Yup.object().shape({
    payment_type: Yup.string().required("Payment type is required"),
    customer: Yup.string().required("Please select customer"),
    sales: Yup.string().required("Please select customer"),
    total_payment: Yup.number(),
    paid_amount: Yup.number(),
    pending_amount: Yup.number(),
    payment_mode: Yup.string().required("Payment mode is required"),
    amount: Yup.string().required("Amount is required"),
    remarks: Yup.string().required("Remarks required"),
  });

  return (
    <div className="flex flex-row h-screen">
      <div className="w-full overflow-y-auto flex flex-col">
        <PaymentNav currentActive="sales" />
        <div className="mt-6 m-6">
          <Formik
            initialValues={salesInitialValues}
            validationSchema={SalesSchema}
            onSubmit={(values) => {
              console.log(values);
            }}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <div className="mb-6">
                  <Label text="Select payment type" isRequired={false} />
                  <div className="flex justify-start gap-6 mt-2">
                    <div>
                      <div className="flex justify-start gap-6">
                        <div>
                          <input
                            className="sr-only peer"
                            type="radio"
                            value="Advance payment"
                            name="payment_type"
                            id="advance_payment"
                            onChange={() => {
                              setFieldValue("payment_type", "advance_payment");
                            }}
                          />
                          <label
                            htmlFor="advance_payment"
                            className={`border border-highlight flex py-3 px-5 shadow-sm text-center rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-blue-500 peer-checked:ring-2 peer-checked:border-transparent justify-center`}
                          >
                            Advance payment
                          </label>
                        </div>
                        <input
                          className="sr-only peer"
                          type="radio"
                          value="Against bill"
                          name="payment_type"
                          id="against_bill"
                          onChange={() => {
                            setFieldValue("payment_type", "against_bill");
                          }}
                        />
                        <label
                          htmlFor="against_bill"
                          className="flex py-3 px-5 shadow-sm text-center border border-highlight rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-blue-500 peer-checked:ring-2 peer-checked:border-transparent justify-center"
                        >
                          Against bill
                        </label>
                      </div>
                      <div>
                        {errors.payment_type && touched.payment_type ? (
                          <span className="text-xs text-red-500">
                            {errors.payment_type}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-x-4 mb-6">
                  <div className="mb-6">
                    <Label text="Customer" isRequired={true} />
                    <SelectInput
                      selectOptions={customers}
                      isError={errors.customer && touched.customer}
                      name="customer"
                      onblur={(e) => {
                        setFieldValue("customer", e.target.value);
                      }}
                    />
                    {errors.customer && touched.customer ? (
                      <span className="text-xs text-red-500">
                        {errors.customer}
                      </span>
                    ) : null}
                  </div>

                  <div className="mb-6">
                    <Label text="Payment mode" isRequired={true} />
                    <SelectInput
                      selectOptions={customers}
                      isError={errors.payment_mode && touched.payment_mode}
                      name="payment_mode"
                      onblur={(e) => {
                        setFieldValue("payment_mode", e.target.value);
                      }}
                    />
                    {errors.payment_mode && touched.payment_mode ? (
                      <span className="text-xs text-red-500">
                        {errors.payment_mode}
                      </span>
                    ) : null}
                  </div>

                  {values.payment_type === "against_bill" && (
                    <>
                      <div className="mb-6">
                        <Label text="Sales" isRequired={true} />
                        <SelectInput
                          selectOptions={sales}
                          isError={errors.sales && touched.sales}
                          name="sales"
                          onblur={(e) => {
                            setFieldValue("sales", e.target.value);
                          }}
                        />
                        {errors.sales && touched.sales ? (
                          <span className="text-xs text-red-500">
                            {errors.sales}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-6">
                        <Label text="Total Payment" isRequired={true} />
                        <TextInput
                          name="total_payment"
                          isError={
                            errors.total_payment && touched.total_payment
                          }
                          disabled={true}
                        />
                        {errors.total_payment && touched.total_payment ? (
                          <span className="text-xs text-red-500">
                            {errors.total_payment}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-6">
                        <Label text="Paid Amount" isRequired={true} />
                        <TextInput
                          name="paid_amount"
                          isError={errors.paid_amount && touched.paid_amount}
                          disabled={true}
                        />
                        {errors.paid_amount && touched.paid_amount ? (
                          <span className="text-xs text-red-500">
                            {errors.paid_amount}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-6">
                        <Label text="Pending Amount" isRequired={true} />
                        <TextInput
                          name="pending_amount"
                          isError={
                            errors.pending_amount && touched.pending_amount
                          }
                          disabled={true}
                        />
                        {errors.pending_amount && touched.pending_amount ? (
                          <span className="text-xs text-red-500">
                            {errors.pending_amount}
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}

                  <div className="mb-6 relative">
                    <Label text="Amount" isRequired={true} />
                    <TextInput
                      placeholder="Enter Amount"
                      name="amount"
                      isError={errors.amount && touched.amount}
                    />
                    {errors.amount && touched.amount ? (
                      <span className="text-xs text-red-500">
                        {errors.amount}
                      </span>
                    ) : null}
                  </div>

                  <div className="mb-6 col-span-2">
                    <Label text="Remarks" isRequired={true} />
                    <TextInput
                      placeholder="Enter Remarks"
                      name="remarks"
                      isError={errors.remarks && touched.remarks}
                    />
                    {errors.remarks && touched.remarks ? (
                      <span className="text-xs text-red-500">
                        {errors.remarks}
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="p-6 flex items-center gap-x-4 w-full justify-end">
                  <button
                    type="submit"
                    className="bg-[#1267FF] py-3 px-16 !text-white rounded-full w-52"
                  >
                    Save
                  </button>
                  <button
                    type="reset"
                    className="border border-[#1267FF] py-3 px-16 !text-[#1267FF] rounded-full w-52"
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Sales;
