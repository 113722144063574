import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
    loading: false,
  },
  reducers: {
    replaceCart: (state, action) => {
      let item = action.payload;
      state.cartItems = item;
    },
    addItemToCart: (state, action) => {
      let item = action.payload;
      state.cartItems.push(item);
    },
    removeItemFromCart: (state, action) => {
      const index = state.cartItems.findIndex(
        (item) => item.id === action.payload
      );
      state.cartItems = state.cartItems.filter((_, i) => i !== index);
    },
    emptyMyCart: (state, action) => {
      state.cartItems = [];
    },
    plusQuantity: (state, action) => {
      state.cartItems = Object.assign(
        [],
        state.cartItems.map((item) => {
          if (item.id === action.payload) {
            item.cart_qnt = item.cart_qnt + 1;
          }
          return item;
        })
      );
    },
    minusQuantity: (state, action) => {
      state.cartItems = Object.assign(
        [],
        state.cartItems.map((item) => {
          if (item.id === action.payload) {
            if (item.cart_qnt !== 1) {
              item.cart_qnt = item.cart_qnt - 1;
            }
          }
          return item;
        })
      );
    },
  },
  extraReducers: {},
});

export const {
  replaceCart,
  addItemToCart,
  removeItemFromCart,
  plusQuantity,
  minusQuantity,
  emptyMyCart
} = cartSlice.actions;
export default cartSlice.reducer;
