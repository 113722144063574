import { Form, Formik } from "formik";
import React from "react";
import { useState } from "react";
import PaymentNav from "../PaymentNav/PaymentNav";
import * as Yup from "yup";
import { Label, SelectInput, TextInput } from "../../../../components";

const Expense = () => {
  const [expenseInitialValues, setExpenseInitialValues] = useState({
    vendor: "",
    account: "",
    amount: "",
    remarks: "",
  });

  const [vendors, setVendors] = useState([]);
  const [account, setAccount] = useState([]);

  const ExpenseSchema = Yup.object().shape({
    vendor: Yup.string().required("Vendor is required"),
    account: Yup.string().required("Account customer"),
    amount: Yup.string().required("Amount is required"),
    remarks: Yup.string().required("Remarks required"),
  });

  return (
    <div className="flex flex-row h-screen">
      <div className="w-full overflow-y-auto flex flex-col">
        <PaymentNav currentActive="expense" />
        <div className="mt-6 m-6">
          <Formik
            initialValues={expenseInitialValues}
            validationSchema={ExpenseSchema}
            onSubmit={(values) => {
              console.log(values);
            }}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <div className="grid grid-cols-3 gap-x-4 mb-6">
                  <div className="mb-6">
                    <Label text="Vendor" isRequired={true} />
                    <SelectInput
                      selectOptions={vendors}
                      isError={errors.vendor && touched.vendor}
                      name="vendor"
                      onblur={(e) => {
                        setFieldValue("vendor", e.target.value);
                      }}
                    />
                    {errors.vendor && touched.vendor ? (
                      <span className="text-xs text-red-500">
                        {errors.vendor}
                      </span>
                    ) : null}
                  </div>

                  <div className="mb-6">
                    <Label text="Account" isRequired={true} />
                    <SelectInput
                      selectOptions={account}
                      isError={errors.account && touched.account}
                      name="account"
                      onblur={(e) => {
                        setFieldValue("account", e.target.value);
                      }}
                    />
                    {errors.account && touched.account ? (
                      <span className="text-xs text-red-500">
                        {errors.account}
                      </span>
                    ) : null}
                  </div>

                  <div className="mb-6">
                    <Label text="Amount" isRequired={true} />
                    <TextInput
                      placeholder="Enter Amount"
                      name="amount"
                      isError={errors.amount && touched.amount}
                    />
                    {errors.amount && touched.amount ? (
                      <span className="text-xs text-red-500">
                        {errors.amount}
                      </span>
                    ) : null}
                  </div>

                  <div className="mb-6 col-span-2">
                    <Label text="Remarks" isRequired={true} />
                    <TextInput
                      placeholder="Enter Remarks"
                      name="remarks"
                      isError={errors.remarks && touched.remarks}
                    />
                    {errors.remarks && touched.remarks ? (
                      <span className="text-xs text-red-500">
                        {errors.remarks}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="p-6 flex items-center gap-x-4 w-full justify-end">
                  <button
                    type="submit"
                    className="bg-[#1267FF] py-3 px-16 !text-white rounded-full w-52"
                  >
                    Save
                  </button>
                  <button
                    type="reset"
                    className="border border-[#1267FF] py-3 px-16 !text-[#1267FF] rounded-full w-52"
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Expense;
