import React, { useEffect, useState } from "react";
import { BiSearchAlt2, BiExpand, BiExitFullscreen } from "react-icons/bi";
import {
  Alert,
  BillingContainer,
  CategoryCard,
  Checkbox,
  Datepicker,
  Label,
  Modal,
  ProductCard,
  RadioButton,
  SelectInput,
  TextInput,
} from "../../../components";
import NoOrder from "../../../assets/no-order.svg";
import { useDispatch, useSelector } from "react-redux";
import { retrieveProducts } from "../../../store/slices/product";
import { addItemToCart } from "../../../store/slices/cart";
import { toast } from "react-hot-toast";
import { retrieveDepartments } from "../../../store/slices/department";
import { useCallback } from "react";
import * as api from "../../../http";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import * as moment from "moment";

const Dashboard = () => {
  const dispatch = useDispatch();

  const [fullScreen, setFullScreen] = useState(false);
  const [products, setProducts] = useState([]);
  const [productImageURL, setProductImageURL] = useState("");
  console.log("productImageURL", productImageURL);
  const [departmentList, setDepartmentList] = useState([]);
  const [addProductModal, setAddProductModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    wholesale_box_barcode: "",
    wholesale_box_batch_number: "",
    wholesale_box_product_name: "",
    department_id: "",
    brand_id: "",
    uom: "",
    sale_tax: "",
    purchase_tax_include: false,
    sales_tax_include: false,
    add_vat: "",
    expiry_date: "",
    purchase_price: "",
    landing_cost: "",
    mrp: "",
    retailer_price: "",
    wholesaler_price: "",
  });

  const cartItems = useSelector((state) => state.cart.cartItems);

  const getAllDept = useCallback(() => {
    dispatch(retrieveProducts({}))
      .then((res) => {
        if (res.payload && res.payload.data && res.payload.data.length > 0) {
          setAllDept(true);

          setProducts(res.payload.data);
          setProductImageURL(res.payload.url);
        }
      })
      .catch((err) => {
        console.error("Product list err: ", err);
      });
  }, [dispatch]);

  useEffect(() => {
    getAllDept();

    dispatch(retrieveDepartments({}))
      .then((res) => {
        if (res.payload.data.length > 0) {
          setDepartmentList(res.payload.data);
        }
      })
      .catch((err) => {
        console.error("Product list err: ", err);
      });
  }, [dispatch, getAllDept]);

  const addToCart = (id) => {
    const item = products.find((item) => item.id === id);
    const check = cartItems.find((item) => item.id === id);
    if (!check) {
      dispatch(
        addItemToCart({
          id: item.id,
          product_name: item.product_name,
          cart_qnt: 1,
          image: productImageURL + item.image,
          mrp: item.mrp,
          add_vat: item.add_vat,
        })
      );
    } else {
      toast.error("Items is already present in cart", {
        position: "top-right",
      });
    }
  };

  const [allDept, setAllDept] = useState(true);
  const [selectedDept, setSelectedDept] = useState(0);
  const [displayAlert, setDisplayAlert] = useState(false);

  const getDeptById = async (id) => {
    try {
      const response = await api.getProductByDept({ department_id: id });

      if (response.status === 200 && response.data.data.length > 0) {
        setAllDept(false);
        setSelectedDept(id);
        setProducts(response.data.data);
      } else {
        toast.error("Products not found with this department", {
          position: "top-right",
        });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  const getProductsByCategory = async (id) => {
    if (id === "all") {
      getAllDept();
    } else {
      await getDeptById(id);
    }
  };

  const searchProduct = async (val) => {
    if (val !== "") {
      try {
        const response = await api.getProductBySearch({ value: val });

        if (response.status === 200 && response.data.data.length > 0) {
          setAllDept(false);
          setSelectedDept(0);
          setProducts(response.data.data);
        } else {
          // setAddProductModal(true)
          toast.error("Products not found with this department", {
            position: "top-right",
          });
        }
      } catch (err) {
        console.log("Err:", err);
      }
    } else {
      getAllDept();
    }
  };

  const ProductSchema = Yup.object().shape({
    image: Yup.mixed().required("Image is required"),
    wholesale_box_barcode: Yup.string().required(
      "Wholesale box barcode is required"
    ),
    wholesale_box_batch_number: Yup.string().required(
      "Wholesale box batch number is required"
    ),
    wholesale_box_product_name: Yup.string().required(
      "Wholesale box product name is required"
    ),
    wholesale_box_product_quantity: Yup.number()
      .typeError("Input should be a number")
      .required("Wholesale box product quantity is required"),
    wholesale_box_product_weight: Yup.string().required(
      "Wholesale box product weight is required"
    ),
    supplier_id: Yup.array()
      .min(1, "Please select supplier")
      .of(Yup.string())
      .required("Supplier is required"),
    barcode: Yup.string().required("Barcode is required"),
    batch_number: Yup.string().required("Batch number is required"),
    product_name: Yup.string().required("Product name is required"),
    department_id: Yup.string().required("Department is required"),
    brand_id: Yup.string().required("Brand is required"),
    net_weight: Yup.string().required("Net weight is required"),
    sale_tax: Yup.string().required("Sales tax is required"),
    purchase_tax_include: Yup.boolean(),
    sales_tax_include: Yup.boolean(),
    add_vat: Yup.string().required("Add Vat is required"),
    expiry_date: Yup.string().required("Expiry date is required"),
    ingredients: Yup.string().required("Ingredients is required"),
    description: Yup.string().required("Description is required"),
    gross_weight: Yup.string().required("Gross Weight is required"),
    nutrition: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        value: Yup.string().required("Name is required"),
      })
    ),
    purchase_price: Yup.number()
      .typeError("Input should be a number")
      .required("Purchase price is required"),
    landing_cost: Yup.number()
      .typeError("Input should be a number")
      .required("Landing cost is required"),
    mrp: Yup.number()
      .typeError("Input should be a number")
      .required("MRP is required"),
    retailer_price: Yup.number()
      .typeError("Input should be a number")
      .required("Retailer Price is required"),
    wholesaler_price: Yup.number()
      .typeError("Input should be a number")
      .required("Wholesaler Price is required"),
  });

  return (
    <div className="flex flex-row">
      <div className="w-2/3 px-6 h-screen overflow-y-auto">
        <div className="flex flex-row justify-between items-center py-2 mb-2">
          <h1 className="font-bold text-xl">Choose Department</h1>
          <div className="flex items-center gap-x-4 cursor-pointer">
            {!fullScreen ? (
              <>
                <BiExpand className="w-5 h-5 text-[#3C81FC]" />
              </>
            ) : (
              <>
                <BiExitFullscreen className="w-5 h-5 text-[#3C81FC]" />
              </>
            )}
          </div>
          <div className="relative w-1/2 shadow-sm">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <BiSearchAlt2 className="w-5 h-5 text-[#3C81FC]" />
            </div>
            <input
              type="text"
              id="simple-search"
              className="border border-[#EAEFF8] text-gray-900 text-sm rounded-md block w-full pl-10 p-2.5 shadow-lg shadow-[#3C81FC]/10 focus:outline-none focus:border-[#142243] focus:ring-[#142243]"
              placeholder="Scan barcode or Enter product number"
              onChange={(e) => searchProduct(e.target.value)}
              autoFocus={true}
            />
          </div>
        </div>
        {/* Categories */}
        <div className="overflow-x-scroll flex flex-row">
          <div onClick={() => getProductsByCategory("all")}>
            <CategoryCard isSelected={allDept} name="All Products" />
          </div>
          {departmentList.length > 0 &&
            departmentList.map((category, index) => (
              <div
                key={index}
                onClick={() => getProductsByCategory(category.id)}
              >
                <CategoryCard
                  isSelected={
                    !allDept && parseInt(category.id) === selectedDept
                  }
                  name={category.name}
                />
              </div>
            ))}
        </div>

        {/* Product listing */}
        <div className="flex flex-row justify-between items-center py-3">
          <h1 className="font-bold text-lg">All Products</h1>
          <span className="text-sm">{products.length} Products</span>
        </div>

        <div className="grid grid-cols-4 gap-x-4">
          {products.length > 0 &&
            products.map((product, index) => (
              <div
                className="cursor-pointer"
                key={index}
                onClick={() => {
                  addToCart(product.id);
                }}
              >
                <ProductCard
                  key={product.id}
                  image={productImageURL + product.image}
                  product_name={product.product_name}
                  mrp={product.mrp}
                  avail_qnt={product.in_qty}
                />
              </div>
            ))}
        </div>
        {products.length === 0 && (
          <div className="flex flex-col justify-center items-center mt-20">
            <img src={NoOrder} alt="no-order" />
            <p className="font-bold">No products available</p>
          </div>
        )}
      </div>
      <BillingContainer />

      {addProductModal && (
        <Modal setIsOpen={setAddProductModal}>
          <div className="h-auto p-6 w-[76rem]">
            <Formik
              initialValues={initialValues}
              validationSchema={ProductSchema}
              enableReinitialize={true}
              onSubmit={async (values) => {
                // values.show_in_pos = "yes";
                // let formData = new FormData();
                // for (var key in values) {
                //   if (key === "supplier_id") {
                //     formData.append(key, JSON.stringify(values[key]));
                //   } else {
                //     formData.append(key, values[key]);
                //   }
                // }
                // try {
                //   const response = await api.createProduct(formData);
                //   if (response.status === 200 || response.status === 201) {
                //     toast.success(response.data.message, {
                //       position: "top-right",
                //     });
                //   } else {
                //     toast.error(response.data.message, {
                //       position: "top-right",
                //     });
                //   }
                // } catch (err) {
                //   toast.error("Something went wrong! Please try again.", {
                //     position: "top-right",
                //   });
                // }
              }}
            >
              {({ errors, touched, values, handleReset, setFieldValue }) => (
                <Form>
                  <div className="">
                    <div className="mb-2">
                      <p className="text-xl font-semibold">
                        Product information
                      </p>
                    </div>

                    <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                      <div className="mb-2">
                        <Label text="Wholesale box barcode" isRequired={true} />
                        <TextInput
                          placeholder="Enter wholesale box barcode"
                          name="wholesale_box_barcode"
                          isError={
                            errors.wholesale_box_barcode &&
                            touched.wholesale_box_barcode
                          }
                        />
                        {errors.wholesale_box_barcode &&
                        touched.wholesale_box_barcode ? (
                          <span className="text-xs text-red-500">
                            {errors.wholesale_box_barcode}
                          </span>
                        ) : null}
                      </div>

                      <div className="mb-2">
                        <Label
                          text="Wholesale box batch number"
                          isRequired={true}
                        />
                        <TextInput
                          placeholder="Enter Wholesale box batch number"
                          name="wholesale_box_batch_number"
                          isError={
                            errors.wholesale_box_batch_number &&
                            touched.wholesale_box_batch_number
                          }
                        />
                        {errors.wholesale_box_batch_number &&
                        touched.wholesale_box_batch_number ? (
                          <span className="text-xs text-red-500">
                            {errors.wholesale_box_batch_number}
                          </span>
                        ) : null}
                      </div>

                      <div className="mb-2">
                        <Label
                          text="Wholesale box product name"
                          isRequired={true}
                        />
                        <TextInput
                          placeholder="Enter wholesale box product name"
                          name="wholesale_box_product_name"
                          isError={
                            errors.wholesale_box_product_name &&
                            touched.wholesale_box_product_name
                          }
                        />
                        {errors.wholesale_box_product_name &&
                        touched.wholesale_box_product_name ? (
                          <span className="text-xs text-red-500">
                            {errors.wholesale_box_product_name}
                          </span>
                        ) : null}
                      </div>

                      <div className="mb-2">
                        <Label text="Department" isRequired={true} />
                        <SelectInput
                          // selectOptions={departments}
                          isError={
                            errors.department_id && touched.department_id
                          }
                          name="department_id"
                          onblur={(e) => {
                            setFieldValue("department_id", e.target.value);
                          }}
                        />
                        {errors.department_id && touched.department_id ? (
                          <span className="text-xs text-red-500">
                            {errors.department_id}
                          </span>
                        ) : null}
                      </div>

                      <div className="mb-2">
                        <Label text="Brand" isRequired={true} />
                        <SelectInput
                          // selectOptions={brands}
                          isError={errors.brand_id && touched.brand_id}
                          name="brand_id"
                          onblur={(e) => {
                            setFieldValue("brand_id", e.target.value);
                          }}
                        />
                        {errors.brand_id && touched.brand_id ? (
                          <span className="text-xs text-red-500">
                            {errors.brand_id}
                          </span>
                        ) : null}
                      </div>

                      <div className="mb-2">
                        <Label text="UOM" isRequired={true} />
                        <SelectInput
                          // selectOptions={brands}
                          isError={errors.uom && touched.uom}
                          name="uom"
                          onblur={(e) => {
                            setFieldValue("uom", e.target.value);
                          }}
                        />
                        {errors.uom && touched.uom ? (
                          <span className="text-xs text-red-500">
                            {errors.uom}
                          </span>
                        ) : null}
                      </div>

                      <div className="mb-2">
                        <div>
                          <Label text="Sale tax" isRequired={true} />
                          <TextInput
                            placeholder="Select Sale tax"
                            name="sale_tax"
                            isError={errors.sale_tax && touched.sale_tax}
                          />
                          {errors.sale_tax && touched.sale_tax ? (
                            <span className="text-xs text-red-500">
                              {errors.sale_tax}
                            </span>
                          ) : null}
                        </div>
                        <div className="flex items-center mt-2 space-x-6">
                          <div className="flex items-center space-x-2">
                            <Checkbox name="purchase_tax_include" />
                            <label className="text-sm">
                              Purchase tax include
                            </label>
                          </div>
                          <div className="flex items-center space-x-2">
                            <Checkbox name="sales_tax_include" />
                            <label className="text-sm">Sale tax include</label>
                          </div>
                        </div>
                      </div>

                      <div className="mb-2">
                        <Label text="Add VAT" isRequired={true} />
                        <div className="flex items-center mt-4 space-x-4">
                          <div className="flex items-center space-x-2">
                            <RadioButton
                              name="add_vat"
                              value="yes"
                              isError={errors.add_vat && touched.add_vat}
                            />
                            <label className="text-sm">Yes</label>
                          </div>
                          <div className="flex items-center space-x-2">
                            <RadioButton
                              name="add_vat"
                              value="no"
                              isError={errors.add_vat && touched.add_vat}
                            />
                            <label className="text-sm">No</label>
                          </div>
                        </div>

                        {errors.add_vat && touched.add_vat ? (
                          <span className="text-xs text-red-500">
                            {errors.add_vat}
                          </span>
                        ) : null}
                      </div>

                      <div className="mb-2">
                        <Label text="Expiry date" isRequired={true} />
                        <Datepicker
                          name="expiry_date"
                          isError={errors.expiry_date && touched.expiry_date}
                          onNewChange={(date) => {
                            console.log(date);
                            setFieldValue(
                              "expiry_date",
                              moment(date).format("dd/MM/YYYY")
                            );
                          }}
                        />
                        {errors.expiry_date && touched.expiry_date ? (
                          <span className="text-xs text-red-500">
                            {errors.expiry_date}
                          </span>
                        ) : null}
                      </div>

                      <div className="my-4 col-span-3">
                        <p className="text-lg font-semibold">Pricing details</p>
                      </div>
                      <div className="mb-2">
                        <Label text="Purchase price" isRequired={true} />
                        <TextInput
                          placeholder="Enter Purchase price"
                          name="purchase_price"
                          isError={
                            errors.purchase_price && touched.purchase_price
                          }
                        />
                        {errors.purchase_price && touched.purchase_price ? (
                          <span className="text-xs text-red-500">
                            {errors.purchase_price}
                          </span>
                        ) : null}
                      </div>

                      <div className="mb-2">
                        <Label text="Landing cost" isRequired={false} />
                        <TextInput
                          placeholder="Enter Landing cost"
                          name="landing_cost"
                          isError={errors.landing_cost && touched.landing_cost}
                        />
                        {errors.landing_cost && touched.landing_cost ? (
                          <span className="text-xs text-red-500">
                            {errors.landing_cost}
                          </span>
                        ) : null}
                      </div>

                      <div className="mb-2">
                        <Label text="MRP" isRequired={true} />
                        <TextInput
                          placeholder="Enter MRP"
                          name="mrp"
                          isError={errors.mrp && touched.mrp}
                        />
                        {errors.mrp && touched.mrp ? (
                          <span className="text-xs text-red-500">
                            {errors.mrp}
                          </span>
                        ) : null}
                      </div>

                      <div className="mb-2">
                        <Label text="Retailer price" isRequired={true} />
                        <TextInput
                          placeholder="Enter Retailer price"
                          name="retailer_price"
                          isError={
                            errors.retailer_price && touched.retailer_price
                          }
                        />
                        {errors.retailer_price && touched.retailer_price ? (
                          <span className="text-xs text-red-500">
                            {errors.retailer_price}
                          </span>
                        ) : null}
                      </div>

                      <div className="mb-2">
                        <Label text="Wholesaler price" isRequired={true} />
                        <TextInput
                          placeholder="Enter Wholesaler price"
                          name="wholesaler_price"
                          isError={
                            errors.wholesaler_price && touched.wholesaler_price
                          }
                        />
                        {errors.wholesaler_price && touched.wholesaler_price ? (
                          <span className="text-xs text-red-500">
                            {errors.wholesaler_price}
                          </span>
                        ) : null}
                      </div>

                      <div className="mb-2 flex items-center gap-x-4 mt-8">
                        <button
                          type="submit"
                          className="bg-[#1267FF] py-3 px-16 !text-white rounded-full w-52"
                        >
                          Save
                        </button>
                        <button
                          onClick={(event) => {
                            event.preventDefault();
                            handleReset();
                          }}
                          className="border border-[#1267FF] py-3 px-16 !text-[#1267FF] rounded-full w-52"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}

      {displayAlert && (
        <Alert type="warning" title="Do you want to add new Product?"></Alert>
      )}
    </div>
  );
};

export default Dashboard;
