import axios from "axios";

const { REACT_APP_BASE_URL, REACT_APP_BASE_TOKEN } = process.env;

const api = axios.create({
  baseURL: `${REACT_APP_BASE_URL}`,
  headers: {
    "app-token": REACT_APP_BASE_TOKEN,
    "Content-type": "application/json",
    Accept: "*/*",
  },
});

api.interceptors.request.use((req) => {
  if (localStorage.getItem("pos-access")) {
    req.headers.Authorization = `${localStorage.getItem("pos-access")}`;
  }
  return req;
});

export const login = (data) => api.post("/login", data);
export const logout = () => api.post("/account/logout", {});

//TODO: Need to get only those products where show_in_pos is yes
export const getAllProducts = (data) => api.post("/product/getall", data);
export const getAllDepartments = (data) => api.post("/department/getall", data);

export const getProductByDept = (data) =>
  api.post("/product/getbydepartment", data);
export const getProductBySearch = (data) =>
  api.post("/product/getbysearch", data);

export const createPayment = (data) => api.post("/order/create", data);

export const getAllRedeems = (data) =>
  api.post("/loyalty-reedeems/getall", data);

export const customerSearch = (data) => api.post("/customer/getbysearch", data);
export const customerDisplayData = (data) =>
  api.post("/front-user-display/getbyid", data);

export const createOrder = (data) => api.post("/order/create", data);
export const listOrders = (data) => api.post("/order/getall", data);
