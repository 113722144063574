import React from "react";

const ProductCard = ({ image, product_name, mrp, avail_qnt }) => {
  const truncateString = (str) => {
    if (str.length > 10) {
      return str.slice(0, 10) + "...";
    } else {
      return str;
    }
  };

  return (
    <div className="shadow-lg shadow-gray-300/50 border rounded-lg border-gray-100 mb-4">
      <div className="flex items-center p-2 gap-x-4">
        <img
          src={image}
          alt={product_name}
          className="w-10 h-10 object-cover rounded-md"
        />
        <div className="flex flex-col">
          <p className="text-slate-900 text-sm font-bold">
            {truncateString(product_name)}
          </p>
          <span className="text-[#525D68] text-[10px]">MRP: {mrp}</span>
        </div>
      </div>
      <div className="p-1 bg-[#142243] rounded-b-lg flex justify-center items-center">
        <p className="text-white text-xs">Avail Qnt: {avail_qnt}</p>
      </div>
    </div>
  );
};

export default ProductCard;
