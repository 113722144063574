import React, { useState } from "react";
import LoginBanner from "../../../assets/login-banner.svg";
import Logo from "../../../assets/logo.svg";
import { Button, Label, TextInput, TextPassword } from "../../../components";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { login } from "../../../store/slices/auth";
import { VscEye } from "react-icons/vsc";

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required("User ID is required"),
    password: Yup.string().required("Password is required"),
  });

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return (
    <div className="flex flex-row w-full">
      <img src={LoginBanner} className="h-screen" alt="login banner" />
      <div className="w-full flex justify-center items-center">
        <div className="w-1/2 h-1/2">
          <img src={Logo} className="mb-14" alt="login banner" />
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            validationSchema={LoginSchema}
            onSubmit={(values) => {
              dispatch(login(values))
                .unwrap()
                .then((data) => {
                  toast.success("Logged in successfully", { position: "top-right" });
                  navigate("/dashboard")
                })
                .catch((err) => {
                  console.error("Err -->", err)
                  toast.error(err.message, { position: "top-right" });
                });
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="flex flex-col">
                  <div className="mb-6">
                    <Label text="User ID" isRequired={true} />
                    <TextInput
                      placeholder="Enter user id"
                      name="username"
                      isError={errors.username && touched.username}
                    />
                    {errors.username && touched.username ? (
                      <span className="text-xs text-red-500">
                        {errors.username}
                      </span>
                    ) : null}
                  </div>
                  <div className="mb-12 relative">
                    <Label text="Password" isRequired={true} />
                    <TextPassword
                      placeholder="**********"
                      name="password"
                      type={passwordShown ? "text" : "password"}
                      isError={errors.password && touched.password}
                    />
                    <div className="absolute right-4 top-12">
                      <VscEye
                        className={
                          passwordShown
                            ? "text-blue-900 cursor-pointer"
                            : "text-gray-500 cursor-pointer"
                        }
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                    {errors.password && touched.password ? (
                      <span className="text-xs text-red-500">
                        {errors.password}
                      </span>
                    ) : null}
                  </div>
                  <div className="mb-6">
                    <Button text="Login" isEnabled="true" type="submit" />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
