import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { BiChevronDown } from "react-icons/bi";
import { Logout } from "../../../components";
import NoOrder from "../../../assets/no-order.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { replaceCart } from "../../../store/slices/cart";

const HoldOrders = () => {
  const [dropDownOpened, setDropDownOpened] = useState(false);
  const openDropDown = () => setDropDownOpened(!dropDownOpened);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [holdOrders, setHoldOrders] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("hold-orders")) {
      let ho = JSON.parse(localStorage.getItem("hold-orders"));
      setHoldOrders(ho);
    }
  }, []);

  const viewHold = (i) => {
    const hItem = holdOrders[i];
    dispatch(replaceCart(hItem.cart_items));
    localStorage.setItem("cart-discount", JSON.stringify(hItem.discount));
    localStorage.setItem(
      "cart-additional-charges",
      JSON.stringify(hItem.additional_charges)
    );

    navigate("/pay");
  };

  return (
    <div className="flex flex-row h-screen">
      <div className="w-full overflow-y-auto flex flex-col">
        <div className="flex flex-row justify-between items-center h-14 px-6 py-2">
          <div className="flex flex-row items-center gap-3">
            <h1 className="font-bold text-xl">Orders</h1>
          </div>
          <div className="flex flex-row items-center gap-2">
            <div
              className="flex justify-end items-center space-x-2 py-2 cursor-pointer"
              onClick={openDropDown}
            >
              <Avatar name="Kunal" round={true} size="40" className="text-xs" />
              <span>Kunal Dholiya</span>
              <button className="relative z-[-10] block rounded-md bg-white p-2 focus:outline-none">
                <BiChevronDown className="h-6 w-6 text-gray-800" />
              </button>
            </div>
          </div>
          {dropDownOpened ? <Logout /> : <></>}
        </div>
        <div className="bg-[#F8F8F8] h-screen">
          <div className="grid grid-cols-3 gap-4 m-6">
            {holdOrders.length > 0 &&
              holdOrders.map((item, index) => (
                <div
                  className="p-6 border rounded-md drop-shadow-md bg-white"
                  key={index}
                >
                  <div className="flex justify-between items-center">
                    <div className="flex flex-col justify-between gap-y-6">
                      <div className="flex flex-col">
                        <p className="text-[#525D68] text-sm">Invoice No</p>
                        <p className="text-[#142243] text-base">
                          {item.invoice_no}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-[#525D68] text-sm">Amount</p>
                        <p className="text-[#142243] text-base">
                          £{item.amount}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col justify-between gap-y-6">
                      <div className="flex flex-col">
                        <p className="text-[#525D68] text-sm">Date</p>
                        <p className="text-[#142243] text-base">{item.date}</p>
                      </div>
                      <div className="flex flex-col">
                        <p className="text-[#525D68] text-sm">Order type</p>
                        <p className="text-[#142243] text-base">
                          {item.order_type !== "" ? item.order_type : "--"}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col justify-between gap-y-6">
                      <div className="flex flex-col">
                        <p className="text-[#525D68] text-sm">Customer name</p>
                        <p className="text-[#142243] text-base">
                          {item.customer_name !== ""
                            ? item.customer_name
                            : "--"}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p
                          onClick={() => viewHold(index)}
                          className="rounded-full py-2 px-10 bg-[#3C81FC] text-white text-sm cursor-pointer"
                        >
                          View
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            {holdOrders.length === 0 && (
              <>
                <div className="col-span-3 flex flex-col items-center justify-center mt-20">
                  <img src={NoOrder} alt="no-order" />
                  <p className="font-bold">No hold orders are available</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoldOrders;
