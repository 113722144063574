import React from "react";

const CategoryCard = ({ name, isSelected }) => {
  return (
    <div className="flex-none py-4 px-2 last:pr-6">
      <div
        className={`border ${
          isSelected ? "border-[#3C81FC]" : null
        } shadow-lg shadow-[#3c82fc21] rounded-lg`}
      >
        <div
          className={`flex flex-col items-center justify-between gap-3 px-5 py-4`}
        >
          <p className="text-slate-900 text-xs font-medium">{name}</p>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
