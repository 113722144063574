import React from "react";
import { BiCaretDown, BiCaretUp, BiFilter } from "react-icons/bi";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Datepicker from "../Form/DatePicker/DatePicker";

const Table = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);
  return (
    <div className="flex flex-col tbl-grid">
      <div className="rounded-t-lg flex justify-between items-center h-14 bg-[#EAEFF8] py-4 px-6">
        <div className="flex flex-row justify-center items-center gap-8">
          <div className="flex items-center gap-2">
            <BiFilter className="h-6 w-6 text-gray-500" />{" "}
            <p className="text-gray-500 text-base">Filter</p>
          </div>
        </div>
        <div className="relative">
          <Datepicker name="expiry_date" />
        </div>
      </div>
      <table {...getTableProps()} className="w-full bg-white border">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="px-6 py-3 text-sm border-b border-gray-300"
                >
                  {/* {column.render("Header")} */}
                  <div className="flex flex-row justify-start items-center space-x-4">
                    <span>{column.render("Header")}</span>
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <BiCaretDown />
                        ) : (
                          <BiCaretUp />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {data.length > 0 ? (
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr className="bg-white border-b" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="px-6 py-3 text-sm border-b  border-gray-200 whitespace-nowrap"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td className="text-center" colSpan={columns.length}>
                {/* <img src={NoOrder} className="text-center" alt="no-order" /> */}
                <p className="font-bold my-12">No data available</p>
              </td>
            </tr>
          </tbody>
        )}
      </table>
      <div className="flex items-center justify-between border-l border-r px-6 py-3 bg-white rounded-b-lg">
        <div className="flex justify-between items-center flex-1">
          <p
            onClick={() => previousPage()}
            className={`${
              !canPreviousPage
                ? "disabled text-gray-400 cursor-not-allowed"
                : ""
            } relative inline-flex items-center text-sm font-medium text-gray-700 cursor-pointer"`}
          >
            Previous
          </p>
          <p
            onClick={() => nextPage()}
            className={`${
              !canNextPage ? "disabled text-gray-400 cursor-not-allowed" : ""
            } relative inline-flex items-center text-sm font-medium text-gray-700 cursor-pointer`}
          >
            Next
          </p>
        </div>
      </div>
    </div>
  );
};

export default Table;
