import React, { useState } from "react";
import Avatar from "react-avatar";
import { BiChevronDown } from "react-icons/bi";
import { Link } from "react-router-dom";
import Icon1 from "../../../assets/o1.svg";
import Icon2 from "../../../assets/o2.svg";
import Icon3 from "../../../assets/o3.svg";
import Icon4 from "../../../assets/o4.svg";
import NoOrder from "../../../assets/no-order.svg";
import { Table } from "../../../components";

const CreditNote = () => {
  const [dropDownOpened, setDropDownOpened] = useState(false);
  const openDropDown = () => setDropDownOpened(!dropDownOpened);

  const orders = [
    {
      key: "Kunal",
    },
  ];

  const data = React.useMemo(
    () => [
      {
        col1: "001",
        col2: "ORD001",
        col3: "12 Apr 2022",
        col4: "Mahima Jain",
        col5: "£25.00",
        col6: "£05.00",
        col7: "£0.00",
      },
      {
        col1: "002",
        col2: "ORD002",
        col3: "12 Apr 2022",
        col4: "Tarak Gada",
        col5: "£20.00",
        col6: "£0.00",
        col7: "£50.00",
      },
      {
        col1: "003",
        col2: "ORD003",
        col3: "12 Apr 2022",
        col4: "Sonali Sharma",
        col5: "£25.00",
        col6: "£0.00",
        col7: "£20.00",
      },
      {
        col1: "004",
        col2: "ORD004",
        col3: "12 Apr 2022",
        col4: "Harshal Modi",
        col5: "£30.00",
        col6: "£2.00",
        col7: "£20.00",
      },
      {
        col1: "005",
        col2: "ORD005",
        col3: "12 Apr 2022",
        col4: "Gopal Patel",
        col5: "£25.00",
        col6: "£40.00",
        col7: "£10.00",
      },
      {
        col1: "006",
        col2: "ORD006",
        col3: "12 Apr 2022",
        col4: "Ashik Mahommad",
        col5: "£25.00",
        col6: "£05.00",
        col7: "£30.00",
      },
      {
        col1: "007",
        col2: "ORD001",
        col3: "12 Apr 2022",
        col4: "John Doe",
        col5: "£20.00",
        col6: "£05.00",
        col7: "£30.00",
      },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "#No",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Invoice No",
        accessor: "col2",
      },
      {
        Header: "Date",
        accessor: "col3",
      },
      {
        Header: "Customer Name",
        accessor: "col4",
      },
      {
        Header: "Total Amount",
        accessor: "col5",
      },
      {
        Header: "Credit use",
        accessor: "col6",
      },
      {
        Header: "Credit Remaining",
        accessor: "col7",
      },
    ],
    []
  );
  return (
    <div className="flex flex-row h-screen">
      <div className="w-full overflow-y-auto flex flex-col">
        <div className="flex flex-row justify-between items-center h-14 px-6 py-2">
          <div className="flex flex-row items-center gap-3">
            <h1 className="font-bold text-xl">Credit note</h1>
          </div>
          <div className="flex flex-row items-center gap-2">
            <div
              className="flex justify-end items-center space-x-2 py-2 cursor-pointer"
              onClick={openDropDown}
            >
              <Avatar name="Kunal" round={true} size="40" className="text-xs" />
              <span>Kunal Dholiya</span>
              <button className="relative z-[-10] block rounded-md bg-white p-2 focus:outline-none">
                <BiChevronDown className="h-6 w-6 text-gray-800" />
              </button>
            </div>
          </div>
          {dropDownOpened ? (
            <div className="absolute right-0 mt-28 mr-8 py-4 w-48 bg-white rounded-md shadow-xl z-20">
              <Link
                to=""
                className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white"
              >
                Sign Out
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="bg-[#F8F8F8] h-screen">
          <div className="grid grid-cols-4 gap-4 m-6">
            <div className="bg-white flex flex-row px-6 py-8 space-x-4">
              <img src={Icon1} alt="total_order" />
              <div>
                <h1 className="font-bold">00</h1>
                <p className="text-sm text-gray-500">Total Order</p>
              </div>
            </div>
            <div className="bg-white flex flex-row px-6 py-8 space-x-4">
              <img src={Icon2} alt="total_amount" />
              <div>
                <h1 className="font-bold">00</h1>
                <p className="text-sm text-gray-500">Total Amount</p>
              </div>
            </div>
            <div className="bg-white flex flex-row px-6 py-8 space-x-4">
              <img src={Icon3} alt="due_amount" />
              <div>
                <h1 className="font-bold">00</h1>
                <p className="text-sm text-gray-500">Due Amount</p>
              </div>
            </div>
            <div className="bg-white flex flex-row px-6 py-8 space-x-4">
              <img src={Icon4} alt="walk_in_customer" />
              <div>
                <h1 className="font-bold">00</h1>
                <p className="text-sm text-gray-500">Walk In Customer</p>
              </div>
            </div>
          </div>

          <div className="mx-6">
            {orders.length > 0 ? (
              <div className="flex flex-col shadow-lg shadow-[#3C81FC]/20">
                <Table columns={columns} data={data} />
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center mt-20">
                <img src={NoOrder} alt="no-order" />
                <p className="font-bold">No orders available</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditNote;
