import React, { useState } from "react";
import Avatar from "react-avatar";
import { BiChevronDown } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { Logout } from "../../../../components";

const PaymentNav = ({ currentActive }) => {
  const [dropDownOpened, setDropDownOpened] = useState(false);
  const openDropDown = () => setDropDownOpened(!dropDownOpened);

  return (
    <>
      <div className="flex flex-row justify-between items-center h-14 px-6 py-2">
        <div className="flex flex-row items-center gap-3">
          <h1 className="font-bold text-xl">Payment</h1>
        </div>
        <div className="flex flex-row items-center gap-2">
          <div
            className="flex justify-end items-center space-x-2 py-2 cursor-pointer"
            onClick={openDropDown}
          >
            <Avatar name="Kunal" round={true} size="40" className="text-xs" />
            <span>Kunal Dholiya</span>
            <button className="relative z-[-10] block rounded-md bg-white p-2 focus:outline-none">
              <BiChevronDown className="h-6 w-6 text-gray-800" />
            </button>
          </div>
        </div>
        {dropDownOpened ? <Logout /> : <></>}
      </div>
      <div className="bg-[#EAEFF8] p-6">
        <p className="text-[#142243] text-sm">Select customer type</p>
        <div className="flex items-center space-x-4 mt-3">
          <NavLink
            to="/payment/sales"
            className={`${
              currentActive === "sales" ? "border border-[#3C81FC]" : null
            } px-8 py-3 bg-white rounded-lg`}
          >
            Sales
          </NavLink>
          <NavLink
            to="/payment/purchase"
            className={`${
              currentActive === "purchase" ? "border border-[#3C81FC]" : null
            } px-8 py-3 bg-white rounded-lg`}
          >
            Purchase
          </NavLink>
          <NavLink
            to="/payment/expense"
            className={`${
              currentActive === "expense" ? "border border-[#3C81FC]" : null
            } px-8 py-3 bg-white rounded-lg`}
          >
            Expense
          </NavLink>
          <NavLink
            to="/payment/cash-in-hand"
            className={`${
              currentActive === "cash-in-hand"
                ? "border border-[#3C81FC]"
                : null
            } px-8 py-3 bg-white rounded-lg`}
          >
            Cash in hands
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default PaymentNav;
