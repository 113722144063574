import React, { useState } from "react";
import Avatar from "react-avatar";
import { BiChevronDown } from "react-icons/bi";
import Icon1 from "../../../assets/o1.svg";
import Icon2 from "../../../assets/o2.svg";
import Icon3 from "../../../assets/o3.svg";
import Icon4 from "../../../assets/o4.svg";
import NoOrder from "../../../assets/no-order.svg";
import { Logout, Table } from "../../../components";
import { useAsyncEffect } from "use-async-effect";
import * as api from "../../../http";
import { toast } from "react-hot-toast";
import * as moment from "moment";

const Order = () => {
  const [dropDownOpened, setDropDownOpened] = useState(false);
  const openDropDown = () => setDropDownOpened(!dropDownOpened);

  const [orders, setOrders] = useState([]);

  useAsyncEffect(async () => {
    try {
      const response = await api.listOrders();
      if (response.status === 200 && response.data.data.length > 0) {
        setOrders(response.data.data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  }, []);

  const data = React.useMemo(() => {
    const formattedData = [];
    console.log("orders -->", orders);
    orders.map((data, index) =>
      formattedData.push({
        col1: index + 1,
        col2: data.invoice_no,
        col3: moment(data.order_date).format("MMM Do YY"),
        col4: data.customer_name,
        col5: data.total_amount,
        col6: data.due_amount,
        col7: data.order_type,
        col8: data.payment_method,
      })
    );
    return formattedData;
  }, [orders]);

  const columns = React.useMemo(
    () => [
      {
        Header: "#No",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Invoice No",
        accessor: "col2",
      },
      {
        Header: "Date",
        accessor: "col3",
      },
      {
        Header: "Customer Name",
        accessor: "col4",
      },
      {
        Header: "Total Amount",
        accessor: "col5",
      },
      {
        Header: "Due Amount",
        accessor: "col6",
      },
      {
        Header: "Order type",
        accessor: "col7",
      },
      {
        Header: "Payment mode",
        accessor: "col8",
      },
    ],
    []
  );

  const getTotalAmount = () => {
    let tm = 0;

    orders.forEach((o) => {
      tm = tm + parseInt(o.total_amount);
    });

    return tm;
  };

  const countWalkInCustomer = () => {
    let c = 0;

    orders.forEach((o) => {
      if(o.order_type === "Walk In") {
        c = c + 1;
      }
    });

    return c;
  }

  return (
    <div className="flex flex-row h-screen">
      <div className="w-full overflow-y-auto flex flex-col">
        <div className="flex flex-row justify-between items-center h-14 px-6 py-2">
          <div className="flex flex-row items-center gap-3">
            <h1 className="font-bold text-xl">Orders</h1>
          </div>
          <div className="flex flex-row items-center gap-2">
            <div
              className="flex justify-end items-center space-x-2 py-2 cursor-pointer"
              onClick={openDropDown}
            >
              <Avatar name="Kunal" round={true} size="40" className="text-xs" />
              <span>Kunal Dholiya</span>
              <button className="relative z-[-10] block rounded-md bg-white p-2 focus:outline-none">
                <BiChevronDown className="h-6 w-6 text-gray-800" />
              </button>
            </div>
          </div>
          {dropDownOpened ? <Logout /> : <></>}
        </div>
        <div className="bg-[#F8F8F8] h-screen">
          <div className="grid grid-cols-4 gap-4 m-6">
            <div className="bg-white flex flex-row px-6 py-8 space-x-4">
              <img src={Icon1} alt="total_order" />
              <div>
                <h1 className="font-bold">{orders.length}</h1>
                <p className="text-sm text-gray-500">Total Order</p>
              </div>
            </div>
            <div className="bg-white flex flex-row px-6 py-8 space-x-4">
              <img src={Icon2} alt="total_amount" />
              <div>
                <h1 className="font-bold">{getTotalAmount()}</h1>
                <p className="text-sm text-gray-500">Total Amount</p>
              </div>
            </div>
            <div className="bg-white flex flex-row px-6 py-8 space-x-4">
              <img src={Icon3} alt="due_amount" />
              <div>
                <h1 className="font-bold">00</h1>
                <p className="text-sm text-gray-500">Due Amount</p>
              </div>
            </div>
            <div className="bg-white flex flex-row px-6 py-8 space-x-4">
              <img src={Icon4} alt="walk_in_customer" />
              <div>
                <h1 className="font-bold">{countWalkInCustomer()}</h1>
                <p className="text-sm text-gray-500">Walk In Customer</p>
              </div>
            </div>
          </div>

          <div className="mx-6">
            {orders.length > 0 ? (
              <div className="flex flex-col shadow-lg shadow-[#3C81FC]/20">
                <Table columns={columns} data={data} />
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center mt-20">
                <img src={NoOrder} alt="no-order" />
                <p className="font-bold">No orders available</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
