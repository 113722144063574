import { Form, Formik } from "formik";
import React from "react";
import PaymentNav from "../PaymentNav/PaymentNav";
import * as Yup from "yup";
import { Label, TextInput } from "../../../../components";

const CashInHand = () => {
  const AmountSchema = Yup.object().shape({
    amount: Yup.string().required("Amount is required"),
  });

  return (
    <div className="flex flex-row h-screen">
      <div className="w-full overflow-y-auto flex flex-col">
        <PaymentNav currentActive="cash-in-hand" />
        <div className="mt-6 m-6">
          <Formik
            initialValues={{ amount: "" }}
            validationSchema={AmountSchema}
            onSubmit={(values) => {
              console.log(values);
            }}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form className="flex flex-col justify-between h-full">
                <div className="mb-6 w-1/3">
                  <Label text="Amount" isRequired={true} />
                  <TextInput
                    placeholder="Enter Amount"
                    name="amount"
                    isError={errors.amount && touched.amount}
                  />
                  {errors.amount && touched.amount ? (
                    <span className="text-xs text-red-500">
                      {errors.amount}
                    </span>
                  ) : null}
                </div>
                <div className="p-6 flex items-center gap-x-4 w-full justify-end">
                  <button
                    type="submit"
                    className="bg-[#1267FF] py-3 px-16 !text-white rounded-full w-52"
                  >
                    Save
                  </button>
                  <button
                    type="reset"
                    className="border border-[#1267FF] py-3 px-16 !text-[#1267FF] rounded-full w-52"
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CashInHand;
