import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Datepicker = ({ onNewChange, name, isTimeRequired, isError }) => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <>
      <DatePicker
        name={name}
        onChange={(date) => setStartDate(date)}
        onSelect={onNewChange}
        selected={startDate}
        showTimeSelect={isTimeRequired}
        dateFormat={isTimeRequired ? "dd/MM/YYYY HH:mm:ss" : "dd/MM/YYYY"}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        shouldCloseOnSelect={true}
        className={`${
          isError ? "!border-red-500" : null
        } border border-[#DBE4F2] mt-2 p-2 h-12 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-full sm:text-sm focus:ring-1`}
      />
    </>
  );
};

export default Datepicker;
